<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th style="width: 180px">
              <InputFilter
                :label="$t('labels.delivery_company_1')"
                :placeholder="$t('labels.delivery_company_1')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 180px">
              <InputFilter
                :label="$t('labels.code')"
                :placeholder="$t('labels.code')"
                name="short_name"
                sort-name="short_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.tracking_link')"
                :placeholder="$t('labels.tracking_link')"
                name="tracking_link"
                sort-name="tracking_link"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 120px">
              <SelectFilter
                :single-line="false"
                :options="yesNoOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="active"
                sort-name="active"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 120px">
              <SelectFilter
                :single-line="false"
                :options="yesNoOptions"
                :label="$t('labels.delivery_partial')"
                :placeholder="$t('labels.delivery_partial')"
                name="delivery_part_available"
                sort-name="delivery_part_available"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 120px">
              <SelectFilter
                :single-line="false"
                :options="yesNoOptions"
                :label="$t('labels.delivery_check_goods')"
                :placeholder="$t('labels.delivery_check_goods')"
                name="delivery_swap_available"
                sort-name="delivery_swap_available"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 120px">
              <SelectFilter
                :single-line="false"
                :options="yesNoOptions"
                :label="$t('labels.delivery_return_required')"
                :placeholder="$t('labels.delivery_return_required')"
                name="delivery_check_available"
                sort-name="delivery_check_available"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="width: 80px">
              <v-btn
                color="success"
                small
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`${index}_${item.id}`"
            class="text-center"
          >
            <td>
              <v-text-field
                v-model="item.name"
                v-if="item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
              <span
                v-else
                class="cursor-pointer primary--text text-decoration-underline"
                @click="showDialogPossibleName(item)"
                >{{ item.name }}</span
              >
            </td>
            <td>
              <v-text-field
                v-model="item.short_name"
                v-if="item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
              <span v-else>{{ item.short_name }}</span>
            </td>
            <td>
              <v-text-field
                v-model="item.tracking_link"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.active"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="true"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.delivery_part_available"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="true"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.delivery_check_available"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="true"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.delivery_swap_available"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="true"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                  class="mb-1"
                  style="width: 52px"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="primary"
                  @click="showDialogGroup(item)"
                  style="width: 52px"
                >
                  {{ $t("labels.group") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                  >{{ $t("labels.save") }}</v-btn
                >
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                  >{{ $t("labels.cancel") }}</v-btn
                >
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialogPossibleName" persistent max-width="480px">
      <DialogPossibleName
        v-if="dialogPossibleName"
        :updating-item="updatingItem"
        @close="hideDialogPossibleName"
      />
    </v-dialog>

    <v-dialog v-model="dialogGroup" persistent max-width="480px">
      <DialogDeliveryGroup
        v-if="dialogGroup"
        :updating-item="updatingItem"
        @close="hideDialogGroup"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { GRAM_OPTIONS, MONEY_OPTIONS, YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "Delivery",
  components: {
    DialogPossibleName: () =>
      import("@/components/delivery/DialogPossibleName.vue"),
    DialogDeliveryGroup: () =>
      import("@/components/delivery/DialogDeliveryGroup.vue"),
    SelectFilter: () => import("@/components/table/SelectFilter.vue"),
    InputFilter: () => import("@/components/table/InputFilter.vue"),
  },
  data: () => ({
    isLoading: false,
    dialogPossibleName: false,
    dialogGroup: false,
    items: [],
    updatingItem: {},
    filters: {
      sort_by: "name",
      sort_type: "asc",
    },
    yesNoOptions: [...YES_NO_OPTIONS],
    moneyOptions: { ...MONEY_OPTIONS },
    gramOptions: { ...GRAM_OPTIONS },
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async getList() {
      const { data } = await httpClient.post(
        "/delivery/v1/company-list",
        this.filters
      );
      this.items = [...data].map((item) => ({
        ...item,
        active: item.active === 1,
        delivery_part_available: item.delivery_part_available === 1,
        delivery_swap_available: item.delivery_swap_available === 1,
        delivery_check_available: item.delivery_check_available === 1,
        editing: false,
        /*ssc_3pl_partner: item.ssc_3pl_partner === 1,
        '3pl_accepted_id_required': item['3pl_accepted_id_required'] === 1,*/
      }));
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        name: null,
        short_name: null,
        tracking_link: null,
        active: true,
        delivery_part_available: false,
        delivery_swap_available: false,
        delivery_check_available: false,
        editing: true,
        /*id_e_marketplace: null,
        max_decrease_weight: null,
        min_weight_after_decrease: null,
        postage_insured_percent: null,
        postage_insured_from_amount: null,
        postage_insured_min: null,
        cod_cost_percent: null,
        cod_cost_min: null,
        cod_cost_from: null,
        notify_email: null,
        ssc_3pl_partner: false,
        "3pl_accepted_id_required": false,*/
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/delivery/v1/update-company", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    showDialogPossibleName(item) {
      this.updatingItem = { ...item };
      this.dialogPossibleName = true;
    },
    hideDialogPossibleName() {
      this.updatingItem = {};
      this.dialogPossibleName = false;
    },
    showDialogGroup(item) {
      this.updatingItem = { ...item };
      this.dialogGroup = true;
    },
    hideDialogGroup() {
      this.updatingItem = {};
      this.dialogGroup = false;
    },
  },
};
</script>

<style scoped></style>
